import { FC } from 'react';
import { isString } from 'lodash';
import { Button, DialogTitle, Dialog, DialogActions, DialogContent, Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import Input from 'src/components/UI/Forms/Input';
import Autocomplete from 'src/components/UI/Forms/Autocomplete';
import RichTextEditor from 'src/components/UI/Forms/RichTextEditor';
import { AddInventoryToPurchaseOrderInput, Inventory, Order, OrderItem } from 'src/generated/dotnet.graphql';
import ListDefaultAutocomplete from 'src/components/UI/Forms/ListDefaultAutocomplete';
import CurrenciesDropdown from 'src/components/Dropdowns/CurrenciesDropdown';
import { useAddInventoryToPurchaseOrder } from 'src/hooks/inventory/useAddInventoryToPurchaseOrder';
import { useGetPurchaseOrders } from 'src/hooks/purchaseOrders/useGetPurchaseOrders';
import { useAppState } from 'src/contexts/app-state';

export enum ReceivedSelectedItemEnum {
  UseDefaults,
  ChooseLocationAndAmount,
  ChooseAmount,
};

interface InjectedProps {
  visible: boolean;
  inventory: Inventory;
  onSave: (responseData: OrderItem, responseMessage: string) => void;
  onCancel: () => void;
}

const AddInventoryToPurchaseOrderDialog: FC<InjectedProps> = ({
  visible,
  onCancel,
  inventory,
  onSave,
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
  } = useForm<any>({
    defaultValues: {
      qty: inventory.orderQty !== null ? inventory.orderQty : inventory.useQty,
      productDescription: inventory.productDescription,
    },
  });
  const { syncRxdb } = useAppState();
  const { purchaseOrders, purchaseOrdersLoading } = useGetPurchaseOrders();
  const { addInventoryToPurchaseOrder, addInventoryToPurchaseOrderLoading } = useAddInventoryToPurchaseOrder(inventory.id);

  const onChange = async (name: string, value: any) => {
    setValue(name, value, { shouldDirty: true });
  };

  const handleCancel = () => {
    onCancel();
    reset();
  };

  const handleSave = async (data: any) => {
    
    let {
      amount,
      order,
      productDescription,
      qCurrency,
      qty,
      unitPrice,
    } = data;

    const unit = isString(qty) ? qty : qty?.member || null;
    const currency = isString(qCurrency) ? qCurrency : qCurrency?.curr || null;

    const payload: AddInventoryToPurchaseOrderInput = {
      inventoryId: inventory.id,
      amount: parseFloat(amount) || 0,
      purchaseOrderId: order.id,
      productDescription,
      currency,
      unit,
      unitPrice
    } as any;

    const { responseData, responseMessage} = await addInventoryToPurchaseOrder(payload);

    if(responseData) {
      onSave(responseData, responseMessage);
      handleCancel();
      syncRxdb();
    }
  };

  const displayExtendedCost = (): number => {
    const amount: number = watch('amount', 0);
    const costPerUnit: number = watch('unitPrice', 0);
    const extCost: number = isNaN(amount) || isNaN(costPerUnit) ? 0 : Number((amount * costPerUnit).toFixed(2));
    return extCost;
  }

  const displayCurrency = (): string => {
    return watch('qCurrency')?.Curr || ""
  }

  return (
    <>
      <form
        className="relative bg-white flex-grow"
        onSubmit={handleSubmit(handleSave)}
      >
        <Dialog
          scroll="paper"
          fullWidth
          maxWidth="md"
          open={visible}
          onClose={handleCancel}
        >
          <DialogTitle>
            <span className="font-bold text-2xl">Add Inventory to PO</span>
          </DialogTitle>
          <DialogContent dividers sx={{ p: 4 }}>
            <Grid container spacing={5} mt={1}>
              <Grid item xs={12}>
                <Autocomplete
                  onChange={onChange}
                  label={"Purchase Order *"}
                  options={purchaseOrders}
                  control={control}
                  name="order"
                  keyExpr="id"
                  displayExpr="orderName"
                  rules={{
                    required: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  name="amount"
                  inputProps={{
                    label: 'Amount *',
                    type: 'number',
                    inputProps: {
                      min: 0,
                      inputMode: 'decimal',
                      step: 1,
                    },
                  }}
                  control={control}
                  rules={{ min: 0, required: true }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ListDefaultAutocomplete
                  onChange={onChange}
                  control={control}
                  name="qty"
                  label="Unit"
                  listName="Qty"
                  freeSolo={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  name="unitPrice"
                  inputProps={{
                    label: 'Cost Per Unit',
                    type: "number",
                    inputProps: {
                      min: 0,
                      inputMode: 'decimal',
                      step: 0.01,
                    },
                  }}
                  rules={{ min: 0 }}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CurrenciesDropdown
                  label="Currency"
                  onChange={onChange}
                  control={control}
                  name="qCurrency"
                />
              </Grid>
              <Grid item xs={12} md={6}>
              <span style={{ fontWeight: 'bold' }}>Extended cost:</span>{` ${displayExtendedCost()} ${displayCurrency()}`}
              </Grid>
              <Grid item xs={12}>
                <RichTextEditor
                  control={control}
                  name="productDescription"
                  onChange={onChange}
                  label="Product Description"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
            <Box  sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}>
              <Button className="mr-2" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                onClick={handleSubmit(handleSave)}
                className="mr-3"
                variant="contained"
              >
                Save
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </form>
    </>
  );
};

export default AddInventoryToPurchaseOrderDialog;
