import { ApolloError, gql, useMutation } from '@apollo/client';
import { InventoryLocation, InventoryLocationInput, UserError } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_INVENTORY_LOCATIONS } from './useGetInventoryLocation';

export const UPSERT_INVENTORY_LOCATION = gql`
  mutation UpsertInventoryLocation($input: InventoryLocationInput!) {
    upsertInventoryLocation(input: $input) {
      inventoryLocation {
        id
        locationId
        serialNum
        amount
        max
        useBy
        isDefault
        locationPath
        dateEntered
      }
      errors {
        code
        message
      }
    }
  }
`;

export interface InventoryLocationMutationResponse {
  responseData?: InventoryLocation;
  responseDataError?: UserError;
  responseMessage: string;
}

interface UpsertInventoryLocationResult {
  upsertInventoryLocation: (input: InventoryLocationInput, isCreate: boolean) => Promise<InventoryLocationMutationResponse>;
  upsertInventoryLocationLoading: boolean;
  upsertInventoryLocationError?: ApolloError;
}

export const useUpsertInventoryLocation = (): UpsertInventoryLocationResult => {
  const [addOrUpdateInventoryLocation, { loading, error }] = useMutation(UPSERT_INVENTORY_LOCATION, {
    onError: (error: ApolloError) => {
      logger('UpsertInventoryLocation').error("Error upserting inventory at location -->", error.message);
    }
  });

  const upsertInventoryLocation = async (input: InventoryLocationInput, isCreate: boolean): Promise<InventoryLocationMutationResponse> => {
    const response = await addOrUpdateInventoryLocation({ 
      variables: { input },
      refetchQueries: [
        {
          query: GET_INVENTORY_LOCATIONS,
          variables: { inventoryId: input.inventoryId }
        },
    ],
    });

    const responseData = response.data?.upsertInventoryLocation?.inventoryLocation;
    const responseDataError = response.data?.upsertInventoryLocation?.errors;

    if (responseData) {
      logger('UpsertInventoryLocation').info(`Inventory ${isCreate ? 'added' : 'updated'} at location successfully`, response.data);
      return {
        responseData,
        responseMessage: `Inventory ${isCreate ? 'added' : 'updated'} at location successfully!`
      };
    } else if (responseDataError) {
      logger('UpsertInventoryLocation').info('Add inventory to location duplicate', response.data);
      return {
        responseDataError: responseDataError[0],
        responseMessage: responseDataError[0].message,
      };
    } else {
      return {
        responseMessage: `Failed to ${isCreate ? 'add' : 'update'} inventory at location!`,
      };
    }
  };

  return { 
    upsertInventoryLocation, 
    upsertInventoryLocationLoading: loading, 
    upsertInventoryLocationError: error,
  };
};
